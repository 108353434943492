<template>	
	<div class="zk-loading" v-if="value">
		<div class="loading-content">
			<div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img src="/images/icon_shouquanzhong.png" style="width:100px;">
				</div>
				<div style="margin-top:12px;color: #444;font-size: 14px;">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>	
</template>
<script>
	export default {
		name: "zk-btn",
		props:{
			value:{
				default:false
			},
			msg:{
				default:''
			}
		},
		data() {
			return {
				style:""
			};
		},
		watch:{
		},
		methods: {
			setImgSrc(type){
				if(type=='success'){
					return "/images/success.png"
				}else if(type=='warning'){
					return  "/images/warning.png"
				}
			}
		},
		mounted() {
			this.setImgSrc()
		},
	};
</script>
<style lang="less" scope>
	
	.zk-loading{
		position: fixed;inset:0;
		background:rgba(0,0,0,0.3);
		display:flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		z-index: 999;
	}
	.zk-loading .loading-content{
		background: #fff;
		width: 130px;
		border-radius: 12px;
		padding: 20px 12px;
		display:flex;
		align-items: center;
		justify-content: center;
	}
	
	
</style>
