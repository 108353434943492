import { getReportDetail } from '@api/report.js';
const useReportStore = {
   state: {
      reportData:false,       // 原始数据
      conclusion:"",          // 问诊 健康评估体质结果
      tzresult:"",            // 问诊 健康评估体质结果 html
      reportType:{
         isWz:      false,       // 问诊
         isWutai:   false,       // 五态问诊
         isWenzhen: false,       // 体质问诊
         isTongue:  false,       // 舌诊
         isPulse:   false,       // 脉诊
         isFace:    false,       // 面诊
         isViscera: false,       // 内脏
         isNeural:  false,       // 经络

      },
      flagNum:0,
      isEnd:false,
      version:"1.0"
   },

   mutations: {
      setDetailData(state, data){
         state.reportData = data;
      },
      setConclusion(state, { conclusion , tzresult }){
         state.conclusion = conclusion;
         state.tzresult = tzresult;
      },
      setReportType(state, data ){
         state.reportType = data;
         console.log(JSON.parse(JSON.stringify(state.reportType)));
      },
      setFlagNum(state, data ){
         state.flagNum++
      },
      setIsEnd(state, data ){
         state.isEnd = data;
      },
      setVersion(state, data ){
         state.version = data;
      }
   },

   actions: {

      handleGetReportDetail({ commit, state },{id}){
         return new Promise((resolve, reject) => {
            getReportDetail(id).then(res=>{
               if(res.respCode==200){
                  commit('setDetailData', res.result);
                  this.dispatch('handleReportType',res.result)
                  this.dispatch('handleConclusion',res.result)
               };
               resolve(res)
            }).catch(err=>{
               reject(err)
            })
         });

      },

      handleReportType({ commit, state } , data ){
         let { wzFlag , faceFlag , szFlag , esbmFlag  , visceraPlan , meridianPlan  } = data;
         let wargData = data.constitutionResult.find(item => item.constitutionQuestionType == 3);
         let questionType = !!wargData ? "3":"2";
         let reportType = {
            ...state.reportType
         };

         if(!!wzFlag){
            if(questionType=="3"){
               reportType.isWutai = true;
            }else{
               reportType.isWenzhen = true;
            };
            reportType.isWz = true;
            commit('setFlagNum')
         };

         if(!!szFlag){
            reportType.isTongue = true;
            commit('setFlagNum')
         };

         if(!!esbmFlag){
            reportType.isPulse = true;
            commit('setFlagNum')
         };

         if(!!faceFlag){
            reportType.isFace = true;
            commit('setFlagNum')
         };

         if(!!visceraPlan){
            reportType.isViscera = true;
         };

         if(!!meridianPlan){
            reportType.isNeural = true;
         };

         commit('setReportType', reportType );

      },

      handleConclusion({ commit, state } , data ){

         let tzresult = "";
         let conclusion = "";

         let wargData = data.constitutionResult.find(item => item.constitutionQuestionType == 3);  // 五态人格问卷
         let tzbsData = data.constitutionResult.find(item =>[1,2].includes(item.constitutionQuestionType) );  // 其它体质问卷
         let questionType = !!wargData ? "3":"2";  // 1:完整  2:简版  3:五态人格    1:为问诊 2-3:为脉诊
         if(questionType=='3'){
            if(wargData.constitution == '掩饰'){
               conclusion = '根据您的五态人格问卷得分, 掩饰分值低于5分, 说明您回答过程注意力不集中, 存在回答不准确的问题, 请重新回答';
            }else{
               conclusion = '五态人格辨识结果：' + wargData.constitution;
            };
            tzresult = wargData.constitution;

         }else if(!!tzbsData){
            let _tzresultHtml;
            if (!tzbsData.tendency || !tzbsData.tendency) {
               conclusion = tzbsData.constitution;
               _tzresultHtml = $('<span style="color:#ed373a">' + tzbsData.constitution + '</span>')
            } else {
               conclusion = tzbsData.constitution + tzbsData.tendency;
               if ( tzbsData.tendency.indexOf("有") !== -1 || tzbsData.tendency.indexOf("兼") !== -1 ) {
               	_tzresultHtml = '<span style="color:#ed373a">' + tzbsData.constitution +
               		'</span></span style="color:#333">' + tzbsData.tendency.substr(0, 1) +
               		'</span><span style="color:#f7931d">' + tzbsData.tendency.substr(1) +
               		'</span>';
               } else {
               	_tzresultHtml = '<span style="color:#ed373a">' + tzbsData.constitution + '</span><span style="color:#f7931d">' + tzbsData.tendency + '</span>';
               }
            };
            tzresult = _tzresultHtml;
            conclusion = '体质问卷结果：' + conclusion;
         };
         commit('setConclusion', { conclusion,tzresult })
      },



   }
}

export default useReportStore
