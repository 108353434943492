import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueVirtualScroller from 'vue-virtual-scroller'
import {  Icon, Toast } from "vant";
import IdCard from './assets/IdCard'
import qs from 'qs';

import 'amfe-flexible';
//调用微信扫一扫
import wx from "weixin-jsapi";

Vue.use(router);
Vue.use(Toast, Icon);

Vue.prototype.wx = wx;
Vue.prototype.$qs = qs;
Vue.prototype.validid = IdCard;
Vue.config.productionTip = false;

// 在man.js中注册 weixin-jsapi


import zkComponents from './views/components/index.js';
Vue.use(zkComponents);

import './permission.js';          // 路由拦截


Vue.directive('fixHeight', {
  // 当被绑定的元素插入到 DOM 中时……
   inserted: function (el) {
		let cols = el.children;
		for (let i = 0; i < cols.length; i++) {
			cols[i].style.height = el.offsetHeight + 'px'
		}

   },
   update: function (el) {
		let cols = el.children;
		for (let i = 0; i < cols.length; i++) {
			cols[i].style.height = 'auto'
		}
		setTimeout(() => {
			for (let i = 0; i < cols.length; i++) {
				cols[i].style.height = el.offsetHeight + 'px'
			}
		}, 500)
   }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')