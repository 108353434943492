<template>	
	<div class="zk-page">
		
	</div>
	
</template>
<script>
	export default {
		name: "information",
		data() {
			return {
				
			};
		},
		components: {
		

		},

		methods: {
			
		},
		mounted() {
			
		},
	};
</script>
<style lang="less" scope>
	
</style>
