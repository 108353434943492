// 加载核心加密库
const CryptoJS = require("crypto-js");
// 加载des算法
const tripledes = require("crypto-js/tripledes");
const key="adsfbsd@#$%^sdfasdgasdf_";

export const dataEnCrypt = (data,username)=>{
    return tripledes.encrypt(data, key+username).toString();
}

export const dataDeCrypt = (data,username)=>{
    return tripledes.decrypt(data, key+username).toString(CryptoJS.enc.Utf8)
}