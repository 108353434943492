<template>
<!-- <a-config-provider :locale="locale"> -->
  <div id="app">
    <!-- <router-view /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- </router-view> -->
  </div>
  <!-- </a-config-provider> -->
</template>
<script>


  export default {
    data() {
      return {
       
      };
    },
    components:{
    // [ConfigProvider.name]: ConfigProvider,
    },
    mounted(){
      
    }
  };
</script>
<style lang="less">
@import './assets/common.css';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  height: 100%;
color: #1B1B1B;;
  font-size: 14px;
  a {
color: #1B1B1B;
  }
}

</style>
