import api from "./api";
//登录
export const login = (params) => {
    return api.post(`/sys/login`,params)
}

//注销登录
export const logout = (params) => {
    return api.get(`/sys/loginout`,params)
}

//注册
export const register = (params) => {
    return api.post(`/sys/register`,params)
}

//获取微信授权
export const getAuth = (params) => {
    return api.get(`/wechat/authorize`,params)
};

//通过 code 获取登录信息
export const getToken = (params) => {
    // return api.get(`/oauth/token`,params)
	 return api.get(`/sys/token`,params)
};


//获取微信扫码需要的参数
export const weixinScan = (params) => {
    return api.get(`/wechat/weixinScan`,params)
}

//验证微信扫描设备是否成功
export const getReidsCustomer = (params) => {
    return api.post(`/scanning/getReidsCustomer`,params)
}


//扫描页面，设置设备扫描用户到redis 中
export const setReidsCustomer = (params) => {
    return api.post(`/scanning/setReidsCustomer`,params)
}

//上传图片
export const systemSet = (params) => {
    return api.post(`/home/saveSystem`,params,{headers: {
        'Content-Type': 'multipart/form-data'
      }})
}

// 授权用户信息给采集设备段
export const empowerUserInfo = (params) => {
    return api.post(`/home/saveSystem`,params,{headers: {
        'Content-Type': 'multipart/form-data'
      }})
}