
import zkColumnNav from './zkColumnNav.vue';
import zkForm from './zkForm.vue';
import zkFormItem from './zkFormItem.vue';
import zkBtn from './zkBtn.vue';
import zkLoading from './zkLoading.vue';
import zkTostPage from './zkTostPage.vue';
import zkNoData from './zkNoData.vue';
export default {
	install(Vue){
		Vue.component('zk-btn',zkBtn);
		Vue.component('zk-column-nav',zkColumnNav);
      Vue.component('zk-form',zkForm);
      Vue.component('zk-form-item',zkFormItem);
		Vue.component('zk-tost-page',zkTostPage);
		Vue.component('zk-loading',zkLoading);
		Vue.component('zk-no-data',zkNoData);
	},
}
