import { Toast } from "vant";
import qs from 'qs';
import router from './router/index.js';
import { getToken } from "@api/system";
import { getCookie , setCookie } from "./util/storage.js";
import { handleWxConfig } from "./util/handleWxConfig.js";

const getCallbackQuery = (query) => {
	let wxCode = query.code ? query.code: "" ;
	let nextPath = query.nextPath ? query.nextPath:"";
	let deviceId = query.deviceId ? query.deviceId:"";
	if(wxCode){
		setCookie('wxCode',wxCode);
	};		
	return { wxCode, nextPath , deviceId }  
};

const gotoTx = (url) => {
	let redirectUrl = url || location.href;                          // "http://sz.weixin.zkxinchuang.cn";   //   url || location.href
	let appId = "wx362c6f05a7ebb611"                                                     
	let wxUrl = "https://open.weixin.qq.com/connect/oauth2/authorize";	
	let locationUrl = `${wxUrl}?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=200#wechat_redirect`;  // responseType: code  or  snsapi_userinfo     
	console.log(locationUrl)
	window.location.href = locationUrl;	
}

router.beforeEach((to, from, next) => {
	console.log(to);
	
	let { path , meta , query ,  params } = to;
	let { requiresAuth , title } = meta;	
	let userInfo = getCookie('userinfo');
	let token = window.localStorage.getItem("token");
	let { wxCode } = getCallbackQuery(query);	
	document.title = title ? title:"中科芯创";
	
	console.log(wxCode)
		
	if(path=='/login'){
		if(token){
			next("/personal")
		}else{
			if(wxCode){
				next();			
			}else{
				gotoTx();	
			}
		};		
		return ;
	};
		
	if( !requiresAuth || ( requiresAuth && token ) ){		
		next();
		if(!getCookie('isWxConfig')){
			// handleWxConfig();
		};		
	} else {	
		setCookie('redirect',to.fullPath);
		if(wxCode){
			getToken({
				wxCode: wxCode,
				state: 1
			}).then( res => {
				if (res.respCode == 200) {	
					if(res.result.token){
						window.localStorage.setItem("token", res.result.token);
					}
					if(res.result.user){
						setCookie('userinfo',res.result.user);
					}
					next()
				}else if(res.respCode == 4001){							
					let _url = location.origin+"/login"; 
					gotoTx(_url)
				}else{
					next(`/login?code=${wxCode}`);
				}
			});			
		}else{
			gotoTx();
		}
	};

})
