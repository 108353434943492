import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import useReportStore from './modules/useReportStore.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters:{
     reportData: state => state.useReportStore.reportData,
     conclusion: state => state.useReportStore.conclusion,
     tzresult: state => state.useReportStore.tzresult,
     flagNum: state => state.useReportStore.flagNum,
     version: state => state.useReportStore.version,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
	 useReportStore
  }
})
