import httpService from './http';
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
const api = {
	get(url, data = {}, other = {}) {
		other = JSON.stringify(other) === '{}' ? {
			headers: { 'Content-Type': 'application/json; charset=utf-8' }
		} : other
		return new Promise((resolve, reject) => {
			httpService.get(url, { params: data }, other).then(res=>{
				resolve(res.data)
			}).catch(err=>{
				Toast('服务未响应，请检查！')
				reject(err)
			})
		})		
		
	},
	post(url, data = {}, other = {}) {
		other = JSON.stringify(other) === '{}' ? {
			headers: { 'Content-Type': 'application/json; charset=utf-8' }
		} : other
		return new Promise((resolve, reject) => {
			httpService.post(url, data, other).then(res=>{
				resolve(res.data)
			}).catch(err=>{
				Toast('服务未响应，请检查！')
				reject(err)
			})
		})
	}
}

export default api;