<template>	
	<div :class="disable ? 'zk-btn not':'zk-btn'" style="" @click="handleClick" :style="style">
		<slot></slot>
	</div>	
</template>
<script>
	export default {
		name: "zk-btn",
		props:{
			disable:{
				default:false
			},
			plan:{
				// default:false
			},
			color:{
				default:'#2D6FDF'
			}
		},
		data() {
			return {
				style:""
			};
		},
		watch:{
			plan(){
				this.setBtnStyle()
			},
			color(){
				this.setBtnStyle()
			},
			disable(){
				this.setBtnStyle()
			}
		},
		methods: {
			handleClick(){
				if(this.disable){
					return false;
				}else{
					this.$emit("click")
				}				
			},
			setBtnStyle(){
				if(this.plan){
					if(this.disable){
						this.style = `border:solid 1px #bbb;background:#fff;color:#555`
					}else{
						this.style = `border:solid 1px ${this.color};background:#fff;color:${this.color}`					
					}
				}else{
					if(this.disable){
						this.style = ``
					}else{
						this.style = `border:solid 1px ${this.color};background:${this.color};`						
					}
				}
			}
		},
		mounted() {
			this.setBtnStyle()
		},
	};
</script>
<style lang="less" scope>
	
	.zk-btn{
		border-radius: 50px;line-height: 42px;color: #fff;text-align: center;margin-bottom: 10px;
	}
	.zk-btn.not{
		background: #bdbdbd;color: #555;
	}
</style>
