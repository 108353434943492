import Cookies from 'js-cookie';
import { dataEnCrypt, dataDeCrypt } from './crypto';

export const getCookie = (key) => {
	try{
		let _k = Cookies.get(key)
		let _obj = JSON.parse(_k)
		return _obj
	}catch(e){
		return Cookies.get(key);
	}	
}
	
export const setCookie = (key,value) => {
	let type = Object.prototype.toString.call(value);
	if( type =="[object Array]" || type =="[object Object]"){
		return Cookies.set(key, JSON.stringify(value));
	}else{
		return Cookies.set(key, value);
	}	
}
	
export const removeCookie = (key) => {
	return Cookies.remove(key);
}


export default {
	setItem(key, value, crypto = false) {
		value = JSON.stringify(value);
		if (crypto) {
			let username = localStorage.getItem('username');
			value = dataEnCrypt(value, username);
		}
		window.localStorage.setItem(key, value);
	},
	getItem(key, crypto = false) {
		var value = window.localStorage.getItem(key);
		if (crypto) {
			let username = localStorage.getItem('username');
			value = dataDeCrypt(value, username);
		}
		if (value && value != 'undefined' && value != 'null') {
			return JSON.parse(value);
		}
		return '';
	},
	
}
