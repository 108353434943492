import axios from "axios";
import api from './api';
export const reportlist = (params) => {
    return api.post(`/record/page`,params)
};
//获取病例报告
export const mzdetail = (id,params) => {
    return api.get(`/record/detail/${id}`,params)
};

//获取舌诊病例报告
export const szdetail = (id,params) => {
    return api.get(`/record/sz/${id}`,params)
};
//获取问诊病例报告
export const questiondetail = (id,params) => {
    return api.get(`/record/question/${id}`,params)
};


//获取病例报告
export const reportdetail = (id,params) => {
    return api.get(`/record/view/${id}`,params)  // record/view/{recordId}
};


export const getReportDetail = (id) => {
    // return api.post(`/recordPdf/recordMZdetailPdfData`,{ id })
	 return api.get(`/record/bxszDetail/${id}`)
	 
	 
};

