<template>	
	<div class="zk-tost-page" v-if="value">
		<div>
			<div style="display: flex;align-items: center;justify-content: center;">
				<img :src="setImgSrc(type)" style="width:120px;">
			</div>
			<div style="height:36px;margin-top:12px;color: #383838;font-size: 18px;" v-if="bigMsg">{{ bigMsg }}</div>
			<div style="height: 60px;color: #545454;" v-if="smallMsg">{{ smallMsg }}</div>			
			<div>
				<slot></slot>
			</div>
		</div>
	</div>	
</template>
<script>
	export default {
		name: "zk-btn",
		props:{
			value:{
				default:false
			},
			type:{
				default:'success'
			},
			bigMsg:{
				default:''
			},
			smallMsg:{
				default:''
			}
		},
		data() {
			return {
				style:""
			};
		},
		watch:{
			type(){
				// this.setImgSrc()
			}
		},
		methods: {
			setImgSrc(type){
				if(type=='success'){
					return "/images/success.png"
				}else if(type=='warning'){
					return  "/images/warning.png"
				}
			}
		},
		mounted() {
			this.setImgSrc()
		},
	};
</script>
<style lang="less" scope>
	
	.zk-tost-page{
		position: fixed;inset:0;
		background: url(/images/img_bg.png) #fff;
		background-repeat: no-repeat;
		background-size:100%;
		background-position: top center;
		display:flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 30px;
	}
	
</style>
