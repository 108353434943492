import storage from "../../util/storage";

export const state = () => ({
  isAuth: true,
  authoritys: window.localStorage.getItem('authoritys') !== null ? storage.getItem('authoritys',true) : {},
})

const getters = {
  //合同
  hasAuthContract(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['order'] !== 'undefined';
  },
  hasAuthContractAdd(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['order']['add'] !== 'undefined';
  },
  hasAuthContractEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['order']['update'] !== 'undefined';
  },
  hasAuthContractDel(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['order']['delete'] !== 'undefined';
  },
  hasAuthContractGet(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['order']['get'] !== 'undefined';
  },
  //客户
  hasAuthClient(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['user'] !== 'undefined';
  },
  hasAuthClientAdd(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['user']['add'] !== 'undefined';
  },
  hasAuthClientEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['user']['update'] !== 'undefined';
  },
  hasAuthClientDel(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['user']['delete'] !== 'undefined';
  },
  hasAuthClientGet(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['user']['get'] !== 'undefined';
  },
  hasAuthClientImport(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['user']['import'] !== 'undefined';
  },
  //产品分类
  hasAuthCategory(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['category'] !== 'undefined';
  },
  hasAuthCategoryAdd(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['category']['add'] !== 'undefined';
  },
  hasAuthCategoryEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['category']['update'] !== 'undefined';
  },
  hasAuthCategoryDel(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['category']['delete'] !== 'undefined';
  },
  //产品
  hasAuthProduct(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['goods'] !== 'undefined';
  },
  hasAuthProductAdd(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['goods']['add'] !== 'undefined';
  },
  hasAuthProductEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['goods']['update'] !== 'undefined';
  },
  hasAuthProductDel(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['goods']['delete'] !== 'undefined';
  },
  //公告
  hasAuthNotice(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['notice'] !== 'undefined';
  },
  hasAuthNoticeAdd(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['notice']['add'] !== 'undefined';
  },
  hasAuthNoticeEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['notice']['update'] !== 'undefined';
  },
  hasAuthNoticeGet(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['notice']['get'] !== 'undefined';
  },
  hasAuthNoticeDel(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['notice']['delete'] !== 'undefined';
  },
  //部门管理
  hasAuthDept(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['apartment'] !== 'undefined';
  },
  hasAuthDeptAdd(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['apartment']['add'] !== 'undefined';
  },
  hasAuthDeptEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['apartment']['update'] !== 'undefined';
  },
  hasAuthDeptGet(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['apartment']['get'] !== 'undefined';
  },
  hasAuthDeptDel(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['apartment']['delete'] !== 'undefined';
  },
   //系统设置
   hasAuthSystem(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['system'] !== 'undefined';
  },
  
  hasAuthSystemEdit(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['system']['update'] !== 'undefined';
  },
  
  //权限
  hasAuthAuthority(state) {
    if(state.isAuth === false) return true;
    return typeof state.authoritys['uu'] !== 'undefined';
  },
}

export const actions = {

  // async getAdministrator({ commit, state }, categoryid) {
  //   let json = await getAdministrator()

  //   commit('setList', json.data)

  //   return json;
  // },

  // async addMenu({ commit, state }, { name, title, parent_id }) {
  //   let json = await addMenu({ name, title, parent_id })
  //   return json
  // },

  // async updateMenu({ commit, state }, { id, name, title, parent_id }) {
  //   let json = await updateMenu({ id, name, title, parent_id })
  //   return json
  // },

  // async delMenu({ commit, state }, id) {
  //   let json = await delMenu(id)
  //   return json
  // }

}

export const mutations = {
  setAuthoritys (state, data) {
    state.authoritys = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
