<template>	
	<div class="zk-no-data" v-if="value">
		<div>
			<div style="display: flex;align-items: center;justify-content: center;">
				<img src="/images/no_data.png" style="width:100px;">
			</div>
			<div style="margin-top:12px;color: #444;font-size: 14px;">
				<slot></slot>
			</div>
		</div>
	</div>	
</template>
<script>
	export default {
		name: "zk-btn",
		props:{
			value:{
				default:false
			},
			msg:{
				default:''
			}
		},
		data() {
			return {
				style:""
			};
		},
		watch:{
		},
		methods: {
			setImgSrc(type){
				if(type=='success'){
					return "/images/success.png"
				}else if(type=='warning'){
					return  "/images/warning.png"
				}
			}
		},
		mounted() {
			this.setImgSrc()
		},
	};
</script>
<style lang="less" scope>
	
	.zk-no-data{
		position: absolute;
		inset:0;
		// height: 100%;
		// width: 100%;
		display:flex;
		background: #fff;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: #f4f4f4;
		z-index: 999;
	}
	
	
</style>
