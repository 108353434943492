<template>	
	<div class="zk-form-item">
		<div class="label">{{label}}</div>
		<div class="value">
			<slot></slot>
		</div>
	</div>
	
</template>
<script>
	export default {
		name: "information",
		props:{
			label:{
				
			}
		},
		data() {
			return {
				
			};
		},

		methods: {
			
		},
		mounted() {
			
		},
	};
</script>
<style lang="less" scope>
	.zk-form-item{
		display: flex;height: 32px;line-height:32px;margin-bottom: 4px;
		// border: dashed 1px #ddd;
	}
	.zk-form-item > div{flex: 1;}
	
	.zk-form-item .label{
		color:#777777;padding-left: 4px;
	}
	.zk-form-item .value{
		color:#333333;
	}
</style>
