// import Vue from 'vue';
import axios from 'axios';
import router from '../router';
import store from '../store';
import { Toast } from 'vant';
import { setCookie } from "@/util/storage.js";

const prdBaseURL = 'http://sz.weixinadmin.zkxinchuang.cn';

const devBaseURL = '/api';


axios.defaults.timeout = 20000 * 100;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? devBaseURL : prdBaseURL;

// http request 拦截器
axios.interceptors.request.use(
	config => {
		// config.headers['Accept-Language'] = localStorage.getItem('SET_LANG');
		let token = window.localStorage.getItem('token')
		config.headers['X-Token'] = token;
		return config;
	},
	err => {
		return Promise.reject(err);
	}
);

// http response 拦截器
axios.interceptors.response.use(
	response => {
		
		if (response.data.respCode == 401) {
			window.localStorage.removeItem('isLogin');
			router.replace({
				name: '/login',
			});
			setCookie('redirect',router.currentRoute.fullPath);
		}else if(response.data.respCode == 200){	
			return response;
		}else if(response.data.respCode == 500){
			// Toast(response.data.respMsg);
			return Promise.reject(response.data);
		}else{
			return response;
		}
		// return response;
	},
	error => {
		
		const { config, code, message } = err
		Toast('服务未响应，请检查！')
		return Promise.reject(error);
	}
);

export default axios;
