import Vue from 'vue'
import VueRouter from 'vue-router'

import axios from 'axios'
Vue.prototype.$axios = axios;

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/DashBoard'),
		meta: {
			title: "中科芯创",
			requiresAuth: true
		}
	},
	{
		path: '/empower',
		name: 'empower',
		component: () => import('../views/empower'),
		meta: {
			title: "授权",
			requiresAuth: true,
			keepAlive: true

		},
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: "登录",
			requiresAuth: false,
			keepAlive: false
		},
		component: () => import('../views/Login'),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/Register'),
		meta: {
			title: "注册",
			requiresAuth: false,
			keepAlive: true
		},
	},
	{
		path: '/analysislist',
		name: 'analysislist',
		component: () => import('../views/analysis/AnalysisList'),
		meta: {
			title: "九种体质辨识",
			requiresAuth: true,
			keepAlive: false
	
		},
	},
	{
		path: '/analysisdetail/:id',
		name: 'analysisdetail',
		component: () => import('../views/analysis/AnalysisItem'),
		meta: {
			title: "九种体质辨识报告",
			requiresAuth: true,
			keepAlive: false
	
		},
	},
	{
		path: '/questionbrief',
		name: 'questionbrief',
		component: () => import('../views/question/QuestionBrief'),
		meta: {
			title: "九种体质辨识介绍",
			requiresAuth: true,
			keepAlive: false
	
		},
	},
	{
		path: '/questiondetail/:code',
		name: 'questiondetail',
		component: () => import('../views/question/QuestionItem'),
		meta: {
			title: "九种体质辨识问卷",
			requiresAuth: false,
			keepAlive: false
	
		},
	},
	{
		path: '/questionresult',
		name: 'questionresult',
		component: () => import('../views/question/QuestionResult'),
		meta: {
			title: "辨识结果",
			requiresAuth: true,
			keepAlive: false
	
		},
	},
	{
		path: '/reportlist',
		name: 'reportlist',
		component: () => import('../views/report/ReportList'),
		meta: {
			title: "报告列表",
			requiresAuth: true,
			keepAlive: true
		},
	},
	{
		path: '/reportdetail/:id',
		name: 'reportdetail',
		component: () => import('../views/report/ReportItem'),
		meta: {
			title: "报告详情",
			requiresAuth: true,
			keepAlive: false
	
		},
	},	
	{
		path: '/reportdetailview',
		name: 'reportdetailview',
		component: () => import('../views/report/ReportItemView.vue'),
		meta: {
			title: "分析报告",
			requiresAuth: true,
			keepAlive: false
	
		},
	},
	{
		path: '/reportPortable',
		name: 'reportPortable',
		component: () => import('../views/reportPortable/reportPortable.vue'),
		meta: {
			title: "便携四诊报告",
			requiresAuth: false,
			keepAlive: false	
		},
	},
	{
		path: '/reportPdf',
		name: 'reportPdf',
		component: () => import('../views/reportPortable/reportPdf.vue'),
		meta: {
			title: "Pdf便携报告",
			requiresAuth: false,
			keepAlive: false	
		},
	},
	
	
	{
		path: '/personal',
		name: 'personal',
		component: () => import('../views/personal/Personal'),
		meta: {
			title: "个人中心",
			requiresAuth: true,
			keepAlive: false

		},
	},
	{
		path: '/information',
		name: 'information',
		component: () => import('../views/personal/Information'),
		meta: {
			title: "个人信息",
			requiresAuth: true,
			keepAlive: false

		},
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/personal/About'),
		meta: {
			title: "关于我们",
			requiresAuth: false,
			keepAlive: false

		},
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: () => import('../views/Agreement'),
		meta: {
			title: "用户协议",
			requiresAuth: false,
			keepAlive: false

		},
	},
	{
		path: '/security',
		name: 'security',
		component: () => import('../views/personal/SecurityChange'),
		meta: {
			title: "安全管理",
			requiresAuth: true,
			keepAlive: false

		},
	},
	{
		path: '/scan',
		name: 'scan',
		meta: {
			title: "扫一扫问卷",
			requiresAuth: true,
			keepAlive: false

		},
		component: () => import('../views/scan/Scan'),
	},

	
	
	/*
	{
		path: '/scandevice',
		name: 'scandevice',
		meta: {
			title: "扫一扫",
			requiresAuth: true,
			keepAlive: false
	
		},
		component: () => import('../views/scan/ScanDevice'),
	},
	*/
	{
		path: '/scanresult',
		name: 'scanresult',
		meta: {
			title: "扫描结果",
			requiresAuth: true,
			keepAlive: false
	
		},
		component: () => import('../views/scan/Result'),
	},
	/*
	{
	  path: '/reporttongue/:id/',
	  name: 'reporttongue',
	  component: () => import('../views/report/ReportTongue'),
	  meta: {
	    title: "舌诊报告",
	    requiresAuth: true,
	    keepAlive: false

	  },
	},
	{
	  path: '/reportquestion/:id/',
	  name: 'reportquestion',
	  component: () => import('../views/report/ReportQuestion'),
	  meta: {
	    title: "问诊报告",
	    requiresAuth: true,
	    keepAlive: false

	  },
	},  
	{
	  path: '/tosearch',
	  name: 'tosearch',
	  component: () => import( '../views/report/ToSearch'),
	  meta: {
	    title: "搜索",
	    requiresAuth: true,
	    keepAlive: false

	  },
	},
	{
	  path: '/searchresult',
	  name: 'searchresult',
	  component: () => import( '../views/report/SearchResult'),
	  meta: {
	    title: "搜索结果",
	    requiresAuth: true,
	    keepAlive: true

	  },
	},  
	*/

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
