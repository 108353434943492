<template>
   <div class="zk-column-nav">
		<div class="font-icon">
			<i :class="icon"></i>
		</div>
		<div class="left-info">
			<slot></slot>
		</div>
		<div style="flex: 1;">
			<slot name="right"></slot>
		</div>
   </div>
</template>
<script>
	export default {
		name: "information",
		props:{
			icon:{
			
			}
		},
		data() {
			return {
				
			};
		},
		methods: {
			
		},
		mounted() {
			
		},
	};
</script>
<style lang="less" scoped>
   .zk-column-nav{
      display: flex;height: 32px;line-height: 32px;
      .font-icon{
			color: #2D6FDF;
      }
      .left-info{
         width: auto;font-size: 16px;color:333;margin-left: 8px;

      }
   }

</style>
