// import wx from 'weixin-js-sdk';
import wx from "weixin-jsapi";
import { weixinScan } from "@api/system";
import { setCookie } from "@/util/storage.js";

export const handleWxConfig = () => {
	return new Promise((resolve, reject) => {
		
		const url = window.location.origin;
		weixinScan({ url:"http://sz.weixin.zkxinchuang.cn" }).then(res => {
			console.log(res)
			if (res.respCode == 200) {
				var timestamp = res.result.timestamp;
				var noncestr = res.result.nonceStr;
				var signature = res.result.signature;
				var appId = res.result.appId;
				wx.config({
					debug: false, 
					appId: appId, // 必填，公众号的唯一标识
					timestamp: timestamp, // 必填，生成签名的时间戳
					nonceStr: noncestr, // 必填，生成签名的随机串
					signature: signature, // 必填，签名，见附录1
					jsApiList: [
						"scanQRCode"
					]
				});
				wx.ready(function() {
					setCookie("isWxConfig",true)
					resolve(true);
				});
				wx.error(function(res) {
					reject(res);
				});
			}
		})
		
	});
};
